.ContactInfoColumn .columnTitle {
  text-transform: uppercase; }

.ContactInfoColumn a {
  color: rgba(3, 6, 0, 0.8); }
  .ContactInfoColumn a:hover {
    color: rgba(0, 0, 0, 0.8); }

@media (max-width: 767px) {
  .ContactInfoColumn .content-flex-end {
    display: flex;
    justify-content: flex-end; } }
