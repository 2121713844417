.BrandLogo .brandLogo {
  flex-direction: column; }
  .BrandLogo .brandLogo h2,
  .BrandLogo .brandLogo h5 {
    margin: 0 auto; }

@media (max-width: 575px) {
  .BrandLogo svg {
    width: 50px;
    height: 30px; }
  .BrandLogo h2 {
    font-size: 1.2rem; }
  .BrandLogo h5 {
    font-size: 0.75rem; } }

@media (min-width: 768px) {
  .BrandLogo h2 {
    font-size: 1.2rem; }
  .BrandLogo h5 {
    font-size: 0.75rem; } }
