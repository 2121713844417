.Navigation {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: #22557b; }
  .Navigation * {
    color: #fff; }
    .Navigation *:hover {
      color: #fff; }
  .Navigation nav {
    padding: 0; }
  .Navigation .push-end {
    flex-direction: row-reverse; }
  .Navigation .MenuIcon svg {
    width: 35px;
    height: 35px; }
  .Navigation .navbarBrandLogo:hover {
    text-decoration: none; }

.SideNav nav {
  background-color: #fffcfd !important; }
  .SideNav nav li {
    background-color: #fffcfd !important;
    cursor: default !important; }
  .SideNav nav h1 {
    background-color: #22557b !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
