.ReviewCard {
  display: flex;
  flex-flow: column;
  position: relative;
  flex: 1 1 50%; }
  .ReviewCard .innerFlex {
    height: 100%;
    display: flex; }
  .ReviewCard .leftQuote,
  .ReviewCard .fiveStars,
  .ReviewCard .rightQuote {
    position: absolute; }
  .ReviewCard .leftQuote {
    top: 0;
    left: 0; }
  .ReviewCard .fiveStars {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .ReviewCard .rightQuote {
    bottom: 0;
    right: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .ReviewCard .slick-dots button:before {
    font-size: 15px; }
  .ReviewCard .slick-arrow:before {
    color: #e10001;
    font-size: 30px; }
  .ReviewCard .slick-prev {
    left: -15px; }
  .ReviewCard .slick-next {
    right: -7px; }
  @media (min-width: 1500px) {
    .ReviewCard {
      flex: 1 1 33.33%; } }
