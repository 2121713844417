.NavItems {
  list-style: none; }
  @media (max-width: 767px) {
    .NavItems {
      padding: 0; }
    .NavItems .nav-link .nav-item {
      color: #030600; }
    .NavItems .nav-link.active,
    .NavItems .nav-link.active .nav-item {
      background-color: #e0e0e0 !important; }
    .NavItems .nav-link:hover,
    .NavItems .nav-link:hover .nav-item {
      background-color: #ebebeb !important; } }
  @media (min-width: 768px) {
    .NavItems .active {
      background-color: #1f3b51; }
    .NavItems .nav-link .nav-item {
      padding: 13px 26px; }
    .NavItems .nav-link:hover {
      background-color: #112c3f; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .NavItems .nav-item {
      font-size: 0.9em; } }
  @media (min-width: 992px) and (max-width: 1500px) {
    .NavItems .nav-item {
      font-size: 0.9em; } }
